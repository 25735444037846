import React from 'react'
import PropTypes from 'prop-types'

import { SegmentContext } from './context'
import Option from './Option'

import { tryCatch } from 'src/funcs/tools'

// ! 2022 8/30 [千瑋]說如果需要複選傾向不會用這元件
// ! --- Compound Component Pattern
// ! https://aglowiditsolutions.com/blog/react-design-patterns/

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// <SegmentedButton
//   size={} // 'sm' : 'md'
//   type={} // 'outlined' : 'solid'
//   value={}
//   disabled={} // bool
//   onChange={(event, value) => setValue(value)}>
//   <SegmentedButton.Option name='1' disabled={}>1</SegmentedButton.Option>
//   <SegmentedButton.Option name='2' disabled={}>2</SegmentedButton.Option>
//   <SegmentedButton.Option name='3' disabled={}>3</SegmentedButton.Option>
//   <SegmentedButton.Option name='4' disabled={}>4</SegmentedButton.Option>
// </SegmentedButton>

const SegmentedButton = props => {
  const {
    size = 'md',
    type = 'solid',
    disabled = false,
    value,
    className = '',
    onChange = () => {},
    children,
  } = props

  const contextProps = {
    containerDisabled: disabled,
    type,
    size,
    value,
    handleChange,
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  function handleChange(event, name) {
    if (!disabled && name !== value) {
      tryCatch(() => onChange(event, name))
    }
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const containerClass = `${className} ${common} ${theme(type, disabled)}`

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <SegmentContext.Provider value={contextProps}>
      <div className={containerClass}>{children}</div>
    </SegmentContext.Provider>
  )
}

SegmentedButton.Option = Option

export default SegmentedButton

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Static CSS      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const common = `grid grid-flow-col auto-cols-fr rounded-[2px] select-none `

// 這層給底色是為了填補下一層 rounded 造成的缺角
const theme = (type, disabled) => {
  if (disabled) return `${shadowCommon} border-dark-3 bg-dark-4`
  if (type === 'solid') return `${shadowCommon} border-transparent bg-dark-3`
  if (type === 'outlined')
    return `${shadowCommon} ${outlinedBeforeBg} shadow-dark-3 border-dark-3 bg-transparent `
}

const outlinedBeforeBg = `relative before:content-[''] before:absolute before:-z-10 before:inset-0 before:bg-dark-1`
const shadowCommon = `shadow-[inset_0_0_1px_1px_transparent]`

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type validate    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
SegmentedButton.propTypes = {
  size: PropTypes.oneOf(['sm', 'md']),
  type: PropTypes.oneOf(['solid', 'outlined']),
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  onChange: PropTypes.func,
}
