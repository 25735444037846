// import * as R from 'ramda'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------     Device List    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------

export const deviceList = {
  //! 過舊不支援
  // UD00eSingle: {
  //   snHead: 'BS',
  //   deviceType: 4,
  //   snBodyLength: 9,
  //   snFoot: '',
  //   hardwareVersion: '',
  //   category: ['freqConverter', 'udbox']
  // },
  //! 過舊不支援
  // UD00eDual: {
  //   snHead: 'BD',
  //   deviceType: 4,
  //   snBodyLength: 9,
  //   snFoot: '',
  //   hardwareVersion: '',
  //   category: ['freqConverter','udbox']
  // },
  BBoxLite28: {
    snHead: 'D',
    deviceType: 7,
    snBodyLength: 9,
    snFoot: '28',
    hardwareVersion: '',
    category: ['beamformers', 'bbox'],
  },
  BBoxLite39: {
    snHead: 'D',
    deviceType: 8,
    snBodyLength: 9,
    snFoot: '39',
    hardwareVersion: '',
    category: ['beamformers', 'bbox'],
  },
  BBoxOne28: {
    snHead: 'D',
    deviceType: 9,
    snBodyLength: 9,
    snFoot: '28',
    hardwareVersion: '',
    category: ['beamformers', 'bbox'],
  },
  BBoxOne39: {
    snHead: 'D',
    deviceType: 10,
    snBodyLength: 9,
    snFoot: '39',
    hardwareVersion: '',
    category: ['beamformers', 'bbox'],
  },
  BBoard28: {
    snHead: 'D',
    deviceType: 12,
    snBodyLength: 9,
    snFoot: '28',
    hardwareVersion: '',
    category: ['beamformers', 'bboard'],
  },
  BBoard39: {
    snHead: 'D',
    deviceType: 13,
    snBodyLength: 9,
    snFoot: '39',
    hardwareVersion: '',
    category: ['beamformers', 'bboard'],
  },
  //! 過舊不支援
  // UD00fSingle: {
  //   snHead: 'BS',
  //   deviceType: 14,
  //   snBodyLength: 9,
  //   snFoot: '',
  //   hardwareVersion: '',
  //   category: ['freqConverter', 'udbox'],
  // },
  //! 過舊不支援
  // UD00fDual: {
  //   snHead: 'BD',
  //   deviceType: 14,
  //   snBodyLength: 9,
  //   snFoot: '',
  //   hardwareVersion: '',
  //   category: ['freqConverter', 'udbox'],
  // },
  UD5gSingle: {
    snHead: 'UD-BS',
    deviceType: 15,
    snBodyLength: 8,
    snFoot: '24',
    hardwareVersion: '',
    category: ['freqConverter', 'udbox_5g'],
  },
  UD5gDual: {
    snHead: 'UD-BD',
    deviceType: 15,
    snBodyLength: 8,
    snFoot: '24',
    hardwareVersion: '',
    category: ['freqConverter', 'udbox_5g'],
  },
  UD5gSingleNI: {
    snHead: 'UD-BS',
    deviceType: 15,
    snBodyLength: 8,
    snFoot: '24',
    hardwareVersion: '0014',
    category: ['freqConverter', 'udbox_5g'],
  },
  UD5gDualNI: {
    snHead: 'UD-BD',
    deviceType: 15,
    snBodyLength: 8,
    snFoot: '24',
    hardwareVersion: '0014',
    category: ['freqConverter', 'udbox_5g'],
  },
  //! WEBTLK-1081 當前沒有 BBox Lite 26 這個產品所以從 Demo mode 拔掉
  //! 2023/11/14 slack 有紀錄 (rd2_app)
  // BBoxLite26: {
  //   snHead: 'D',
  //   deviceType: 18,
  //   snBodyLength: 9,
  //   snFoot: '26',
  //   hardwareVersion: '',
  //   category: ['beamformers','bbox'],
  // },
  BBoxOne26: {
    snHead: 'D',
    deviceType: 19,
    snBodyLength: 9,
    snFoot: '26',
    hardwareVersion: '',
    category: ['beamformers', 'bbox'],
  },
  BBoxLiteNI: {
    snHead: 'D',
    deviceType: 20,
    snBodyLength: 9,
    snFoot: '',
    hardwareVersion: '',
    category: ['beamformers', 'bbox', 'ni'],
  },
  BBoxOneNI: {
    snHead: 'D',
    deviceType: 21,
    snBodyLength: 9,
    snFoot: '',
    hardwareVersion: '',
    category: ['beamformers', 'bbox', 'ni'],
  },
  // 舊殼
  PowerDetector26_01: {
    snHead: 'PD-',
    deviceType: 22,
    snBodyLength: 8,
    snFoot: '',
    hardwareVersion: '01',
    category: ['pd'],
  },
  // 新殼(鏤空)
  PowerDetector26_02: {
    snHead: 'PD-',
    deviceType: 22,
    snBodyLength: 8,
    snFoot: '',
    hardwareVersion: '02',
    category: ['pd'],
  },
  UDModule0620: {
    snHead: 'UDM-',
    deviceType: 23,
    snBodyLength: 7,
    snFoot: '0620',
    hardwareVersion: '',
    category: ['freqConverter', 'ud_module'],
  },
  UDBox0630: {
    snHead: 'UDB-',
    deviceType: 24,
    snBodyLength: 7,
    snFoot: '0630',
    hardwareVersion: '',
    category: ['freqConverter', 'udbox_0630'],
  },
  //! 專案尚未啟動
  // UDBox1753: {
  //   snHead: 'UDB-',
  //   deviceType: 24,
  //   snBodyLength: 7,
  //   snFoot: '1753',
  //   hardwareVersion: '',
  //   category: ['freqConverter', 'udbox_0630'],
  // },
  CloverCellEvb: {
    snHead: 'tbd-',
    deviceType: 25,
    snBodyLength: 7,
    snFoot: '',
    hardwareVersion: '',
    category: ['beamformers', 'clover_cell', 'clover_cell_evb'],
  },
  SG: {
    snHead: 'tbd-',
    deviceType: 27,
    snBodyLength: 7,
    snFoot: '',
    hardwareVersion: '',
    category: ['freqConverter', 'sg'],
  },
  RIS_V0: {
    snHead: 'tbd-',
    deviceType: 28,
    snBodyLength: 7,
    snFoot: '',
    hardwareVersion: '0',
    category: ['beamformers', 'ris'],
  },
  RIS_V1: {
    snHead: 'tbd-',
    deviceType: 28,
    snBodyLength: 7,
    snFoot: '',
    hardwareVersion: '1',
    category: ['beamformers', 'ris'],
  },

  CloverCellArray1024: {
    snHead: 'tbd-',
    deviceType: 255,
    snBodyLength: 7,
    snFoot: '',
    hardwareVersion: '',
    category: ['beamformers', 'clover_cell', 'clover_cell_array_1024'],
  },
  CloverCellCTTC_1: {
    snHead: 'AiP-88C',
    deviceType: 254,
    snBodyLength: 3,
    snFoot: '1',
    hardwareVersion: '',
    category: ['beamformers', 'clover_cell', 'clover_cell_cttc'],
  },
  CloverCellCTTC_4: {
    snHead: 'AiP-88C',
    deviceType: 254,
    snBodyLength: 3,
    snFoot: '4',
    hardwareVersion: '',
    category: ['beamformers', 'clover_cell', 'clover_cell_cttc'],
  },
}

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Export List      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const D = deviceList

//* 如果想要套用全部 device 直接用 allDevice
// ex: let mockServerDeviceList = allDevice
// 記得 FE 還沒 support 的 deviceType 要去上面註解掉不然會報錯
// const allDevice = R.values(D)

//! BBox One 26 經 Ivy 指示從 Demo mode 拔掉
//! 2024/05/21 slack 有紀錄 (Ethan & Ivy 個人頻道)
//* ----------------- ------------------ -----------------
//* 決定 demo mode 要顯示哪些 device
let demoDeviceList = [
  D?.RIS_V1,
  D?.RIS_V0,
  D?.BBoxLite28,
  D?.BBoxLite39,
  D?.BBoxOne28,
  D?.BBoxOne39,
  D?.BBoard28,
  D?.BBoard39,
  D?.UD5gSingle,
  D?.UD5gDual,
  D?.BBoxOne26,
  D?.PowerDetector26_02,
  D?.UDModule0620,
  D?.UDBox0630,
  // D?.CloverCellArray1024,
  // D?.CloverCellEvb,
  // D?.SG,
  // D?.CloverCellCTTC_4,
  // D?.CloverCellCTTC_1,
  // D?.CloverCellCTTC_1,
]

//* ----------------- ------------------ -----------------
//* 決定 fe 開發用的 server 要顯示哪些 device
let mockServerDeviceList = [
  D?.RIS_V1,
  D?.RIS_V0,
  D?.CloverCellCTTC_4,
  D?.CloverCellCTTC_1,
  D?.CloverCellCTTC_1,
  D?.SG,
  D?.UDBox0630,
  D?.BBoxOne28,
  D?.UD5gDual,
  D?.PowerDetector26_02,
  D?.CloverCellEvb,
  D?.CloverCellArray1024,
]

//* ----------------- ------------------ -----------------
export { demoDeviceList, mockServerDeviceList }
