import { deviceError as handleDeviceErrorResponse } from 'src/redux/sagas/handlers/errorResponse/general/deviceError'
import { commonAfterImport } from 'src/redux/sagas/handlers/event/beamformers/ris/v0/common'

import { steeringThetaPhi as handleSteeringThetaPhiV0Response } from 'src/redux/sagas/handlers/response/beamformers/ris/v0/steering'
import { steeringThetaPhi as handleSteeringThetaPhiV1Response } from 'src/redux/sagas/handlers/response/beamformers/ris/v1/steering'

const risApi = {
  RIS_STEERING_CHANGE: {
    emit: {
      server: 'beamformers',
      eventName: 'ris/beam/steering/thetaPhiDistance',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'ris/beam/steering/thetaPhiDistance/response',
      handler: handleSteeringThetaPhiV0Response,
      errorHandler: handleDeviceErrorResponse,
    },
  },
  RIS_PATTERN_IMPORT: {
    emit: {
      server: 'beamformers',
      eventName: 'ris/pattern/import',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'ris/pattern/import/response',
      handler: commonAfterImport,
      errorHandler: handleDeviceErrorResponse,
    },
  },

  RIS_STEERING_CHANGE_V1: {
    emit: {
      server: 'beamformers',
      eventName: 'ris/beam/steering/thetaPhiDistance/v1',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'ris/beam/steering/thetaPhiDistance/response',
      handler: handleSteeringThetaPhiV1Response,
      errorHandler: handleDeviceErrorResponse,
    },
  },
  RIS_PATTERN_IMPORT_V1: {
    emit: {
      server: 'beamformers',
      eventName: 'ris/pattern/import/v1',
      beforeEmitActionType: null,
      throttle: true,
      timeout: null,
    },
    on: {
      eventName: 'ris/pattern/import/response',
      handler: commonAfterImport,
      errorHandler: handleDeviceErrorResponse,
    },
  },
}

export default risApi
