import React from 'react'

import AllPower from 'src/containers/operating/device/beamformers/bboard/Step/AllPower'
import Main from 'src/containers/operating/device/beamformers/bboard/Step/Main'
import ReadTemperature from 'src/containers/operating/device/beamformers/bboard/Step/ReadTemperature'
import SetTemperature from 'src/containers/operating/device/beamformers/bboard/Step/SetTemperature'

const Step = props => {
  return (
    <div className={container}>
      <AllPower />

      <div className={divider} />

      <Main />

      <div className={divider} />

      <ReadTemperature />

      <div className={divider} />

      <SetTemperature />
    </div>
  )
}

export default Step

const container = `[Step-container] funcBG rounded-md px-3 pb-[140px]`
const divider = `w-full h-[1px] bg-dark-5`
