import { call, select } from 'redux-saga/effects'
import * as R from 'ramda'

import { devWarLog } from 'src/funcs/tools'

export function* getCurrentData(sn) {
  try {
    const deviceData = yield select(state => state.deviceData)
    return yield R.clone(deviceData[sn])
  } catch (error) {
    devWarLog(error)
  }
}

export function* getCurrentRfMode(sn) {
  try {
    const currentData = yield call(getCurrentData, sn)
    const result = currentData.deviceControl.common.rfMode
    return yield R.clone(result)
  } catch (error) {
    devWarLog(error)
  }
}

export function* getCommonArgs(sn) {
  try {
    const currentData = yield call(getCurrentData, sn)
    const lookupID = currentData?.info.lookupID
    const hwVersion = currentData?.info?.versionInfo?.hardware

    // bbox
    const currentRfMode = currentData?.deviceControl?.common?.rfMode
    return { currentData, lookupID, hwVersion, currentRfMode }
  } catch (error) {
    devWarLog(error)
  }
}
