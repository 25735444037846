import React from 'react'

import Common from 'src/containers/operating/device/beamformers/ris/v0/Common'
import BeamSteering from 'src/containers/operating/device/beamformers/ris/v0/BeamSteering'
import Channel from 'src/containers/operating/device/beamformers/ris/v0/Channel'

const RisAlpha = props => {
  return (
    <section className={'flex flex-col xl:flex-row items-start gap-4'}>
      <div className='max-w-[632px] w-full '>
        <div className='funcBG py-3 px-5 rounded-md mb-4'>
          <Common />
        </div>

        <div className='funcBG p-4 rounded-md'>
          <BeamSteering />
        </div>
      </div>

      <div className='funcBG p-4 rounded-md max-w-[632px] xl:max-w-[416px] w-full '>
        <Channel />
      </div>
    </section>
  )
}

RisAlpha.propTypes = {}

export default RisAlpha
