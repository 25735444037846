import React from 'react'
import { useDispatch } from 'react-redux'

import LEDIndicators from 'src/containers/operating/device/freqConverter/udbox/UD5GDeviceSettings/LEDIndicators'
import RefSource from 'src/containers/operating/device/freqConverter/udbox/UD5GDeviceSettings/RefSource'
import SwitchControl from 'src/containers/operating/device/freqConverter/udbox/UD5GDeviceSettings/SwitchControl'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import { getUdChannelMode } from 'src/funcs/getDeviceInfo'

import { hDivider } from 'src/containers/operating/device/freqConverter/style'

import { udbox_deviceSettings_watcher } from 'src/redux/actions/freqConverter/udboxAction'

import { modalActions } from 'src/redux/slices/modal'

const UD5GDeviceSettings = props => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()
  const sn = current.sn

  const { CH1, CH2, OUT_10M, OUT_100M, SOURCE_100M, PWR_5V, PWR_9V } =
    current.data.settings.device

  const isDual = getUdChannelMode(sn) === 'dual'

  const handleChange = ({ label, newValue }) => {
    if (label === 'SOURCE_100M' && +newValue === 1 && SOURCE_100M !== newValue)
      return dispatch(modalActions.showModalOfUDBoxExternalCheck({ sn }))

    return dispatch(
      udbox_deviceSettings_watcher({ sn, label, value: newValue })
    )
  }
  const chProps = {
    title: 'Channel Control',
    option1: { subtitle: 'CH 1', label: 'CH1', value: CH1 },
    option2: isDual ? { subtitle: 'CH 2', label: 'CH2', value: CH2 } : null,
    handleChange,
  }

  const OutputRefProps = {
    title: 'Output Reference',
    option1: { subtitle: '10 MHz', label: 'OUT_10M', value: OUT_10M },
    option2: {
      subtitle: '100 MHz',
      label: 'OUT_100M',
      value: OUT_100M,
      disabled: SOURCE_100M === 1,
    },
    handleChange,
  }

  const OutputVoltProps = {
    title: 'Output Voltage',
    option1: { subtitle: '+5 V ext', label: 'PWR_5V', value: PWR_5V },
    option2: { subtitle: '+9 V ext', label: 'PWR_9V', value: PWR_9V },
    handleChange,
  }

  return (
    <div className={container}>
      <LEDIndicators />

      <div className={hDivider} />

      <SwitchControl {...chProps} />

      <div className={hDivider} />

      <RefSource value={SOURCE_100M} handleChange={handleChange} />

      <div className={hDivider} />

      <SwitchControl {...OutputRefProps} />

      <div className={hDivider} />

      <SwitchControl {...OutputVoltProps} />
    </div>
  )
}

UD5GDeviceSettings.propTypes = {}

export default UD5GDeviceSettings

const container = `[UD5GDeviceSettings-container] 
                  funcBG 
                  w-full
                  rounded-md
                  px-4 pt-4 pb-10 
                  flex flex-col 
                  gap-y-4 
                  `
