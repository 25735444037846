import React, { useEffect, useLayoutEffect } from 'react'
import { Amplify } from 'aws-amplify'
import { useDispatch } from 'react-redux'
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'

import Background from 'src/containers/Background'
import Header from 'src/containers/Header'

import ChooseMode from 'src/pages/facility/ChooseMode'
import Settings from 'src/pages/facility/Settings'
import Home from 'src/pages/facility/Home'
import DownloadMid from 'src/pages/facility/DownloadMid'
import PageNotFound from 'src/pages/facility/PageNotFound'

import Operating from 'src/pages/Operating'
import Bbox from 'src/pages/Operating/device/beamformers/Bbox'
import Bboard from 'src/pages/Operating/device/beamformers/Bboard'
import Ris from 'src/pages/Operating/device/beamformers/Ris'
import CloverCellEvb from 'src/pages/Operating/device/beamformers/CloverCellEvb'
import Udbox from 'src/pages/Operating/device/freqConverter/Udbox'
import UdModule from 'src/pages/Operating/device/freqConverter/UdModule'
import SG from 'src/pages/Operating/device/freqConverter/SG'
import PowerDetector from 'src/pages/Operating/device/PowerDetector'

import CloverCellArray1024 from 'src/pages/Operating/device/beamformers/CloverCellArray1024'
import CloverCellCttc from 'src/pages/Operating/device/beamformers/CloverCellCttc'

import Modal from 'src/containers/Modal'
import NavigateSetter from 'src/containers/NavigateSetter'
import PrivacyBar from 'src/containers/PrivacyBar'
import DemoFooter from 'src/containers/DemoFooter'
import FixedButton from 'src/containers/FixedButton'

import ErrorBoundaryOfGeneralError from 'src/containers/ErrorBoundary/GeneralError'

// import ComponentsDemo from 'src/pages/ComponentsDemo'

import { getServiceVersionWatcher } from 'src/redux/slices/uiControl/facility/versions'
import { notification_getMaintenance_watcher } from 'src/redux/actions/facility/notification'

import { member_updateInfo_watcher } from 'src/redux/actions/facility/member'

import { amplifyConfig } from 'src/configs/awsConfig'
Amplify.configure(amplifyConfig)

const App = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getServiceVersionWatcher())
    dispatch(member_updateInfo_watcher())
    dispatch(notification_getMaintenance_watcher())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 偵測當前是否斷線 (尚未實際應用)
  // const [isOnline, setIsOnline] = useState(navigator.onLine)
  // useEffect(() => {
  //   const handleStatusChange = () => {
  //     setIsOnline(navigator.onLine)
  //   }

  //   window.addEventListener('online', handleStatusChange)

  //   window.addEventListener('offline', handleStatusChange)

  //   return () => {
  //     window.removeEventListener('online', handleStatusChange)
  //     window.removeEventListener('offline', handleStatusChange)
  //   }
  // }, [isOnline])

  return (
    <BrowserRouter>
      <Modal />
      <ErrorBoundaryOfGeneralError>
        <NavigateSetter />
        <Background />
        <FixedButton />

        <AutoScrollToTop>
          <section className={section}>
            <Header />

            <main className='min-h-screen h-full py-[136px] lg:py-[88px]'>
              <Routes>
                <Route path='/' element={<ChooseMode />} />

                <Route path='/settings' element={<Settings />} />

                <Route path='download/middleware' element={<DownloadMid />} />

                <Route path='home/:mode' element={<Home />} />

                <Route path='operating/:mode' element={<Operating />}>
                  {/* 用 getDeviceInfo 的 devName 的 first 作為 path */}
                  {/* Standard Product */}
                  {/* Standard Product */}
                  {/* Standard Product */}
                  <Route path='bbox/:sn' element={<Bbox />} />
                  <Route path='bboard/:sn' element={<Bboard />} />
                  <Route path='ris/:sn' element={<Ris />} />

                  <Route path='cloverCellEvb/:sn' element={<CloverCellEvb />} />

                  <Route path='udbox/:sn' element={<Udbox />} />
                  <Route path='udModule/:sn' element={<UdModule />} />
                  <Route path='sg/:sn' element={<SG />} />

                  <Route path='pd/:sn' element={<PowerDetector />} />

                  {/* Custom Product */}
                  {/* Custom Product */}
                  {/* Custom Product */}
                  <Route
                    path='cloverCellArray1024/:sn'
                    element={<CloverCellArray1024 />}
                  />
                  <Route
                    path='cloverCellCttc/:sn'
                    element={<CloverCellCttc />}
                  />
                </Route>

                {/* <Route path='demo' element={<ComponentsDemo />} /> */}

                <Route path='*' element={<PageNotFound />} />
              </Routes>

              <PrivacyBar />
              <DemoFooter />
            </main>
          </section>
        </AutoScrollToTop>
      </ErrorBoundaryOfGeneralError>
    </BrowserRouter>
  )
}

const section = 'w-full min-h-screen h-full relative z-0'

export default App

//* 每次換頁都會自動 scroll to top
// https://stackoverflow.com/questions/70193712/how-to-scroll-to-top-on-route-change-with-react-router-dom-v6
const AutoScrollToTop = ({ children }) => {
  const location = useLocation()

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [location.pathname])

  return children
}
