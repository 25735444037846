import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'

import CommonButton from 'src/components/Button/CommonButton'

import noDataImg from 'src/assets/img/no_data.png'

import { calibrationTableImportApi } from 'src/redux/sagas/services/restfulAPI/dbServiceApi'
import { bbox_noTable_Import_watcher } from 'src/redux/actions/beamformers/bboxAction'
import { modalActions } from 'src/redux/slices/modal'

import useUploadFiles from 'src/hooks/useUploadFiles'

const NoCalibrationTable = ({ sn }) => {
  const dispatch = useDispatch()
  const inputFileRef = useRef()

  const handleBeforeTableImport = () =>
    dispatch(modalActions.showModalOfFileImporting())
  const handleAfterTableImport = responseData => {
    dispatch(bbox_noTable_Import_watcher({ sn, responseData }))
  }

  const { handleFileInputChange } = useUploadFiles({
    inputFileRef,
    api: calibrationTableImportApi,
    payload: '{}',
    beforeCallback: handleBeforeTableImport,
    afterCallback: handleAfterTableImport,
  })

  const handleImportClick = () => inputFileRef.current.click()

  return (
    <section className={initSection}>
      <img src={noDataImg} alt='tmxlab-kit no data Img' className='mb-8' />

      <div className='text-lg text-light-4 font-bold leading-5'>
        No Calibration table. <br />
        Click to import the Central Frequency Calibration table to get started.{' '}
        <br />
        The file name is the product serial number + frequency
      </div>

      <input
        className='hidden'
        ref={inputFileRef}
        type='file'
        accept='.csv'
        multiple
        onChange={handleFileInputChange}
      />
      <CommonButton
        type='general'
        size='lg'
        className='mt-16'
        onClick={handleImportClick}>
        Import
      </CommonButton>
    </section>
  )
}

export default NoCalibrationTable

// h-[calc(100vh_-_64px_-_24px_-_84px)]
// 64px = 上方空白處
// 24px = operating 下方 padding
// 84px - footer 的 height
const initSection = `[NoCalibrationTable-initSection] 
                funcBG   
                relative 
                w-full 
                h-[calc(100vh_-_64px_-_24px_-_84px)] 
                flex flex-col 
                items-center justify-center 
                text-light-4 text-3xl font-bold text-center 
                `
