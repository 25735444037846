import React, {
  useLayoutEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
  forwardRef,
} from 'react'
import PropTypes from 'prop-types'
import Decimal from 'decimal.js'
import { mergeRefs } from 'react-merge-refs'

import InputCore from 'src/components/Input/Core'
import ProgressBar from 'src/components/ProgressBar'

import { floorProcess, ceilProcess } from 'src/components/Input/func'

import Left from 'src/components/Input/InputGroup/Left'
import Right from 'src/components/Input/InputGroup/Right'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// <InputGroup
//   {...{
//     size, // 'sm', 'md', 'lg'
//     value, // string
//     emptyValue // string

//     unit, // node
//     disabled, // bool
//     danger, // bool
//     warning, // bool
//     off, // bool
//     showProgress, // bool
//     readOnly, // bool
//     placeholder, // string

//     step, // number
//     loop  // bool
//     inputMin  // number
//     inputMax  // number
//     keydownMin  // number
//     keydownMax  // number
//     decimalScale = 0  // number
//   }}
//   inputMin={0}
//   inputMax={0}
//   keydownMin={0}
//   keydownMax={0}
//   progressValue={[0, 0]}
//   progressMin={[0, 0]}
//   progressMax={[0, 0]}
//   icon={<></>}
//   onChange={result => console.log(result.formattedValue)}
// />

const InputGroup = forwardRef((props, ref) => {
  const {
    size = 'md',
    value,
    emptyValue,

    inputType = 'number',
    icon,
    unit,
    off = false,
    disabled = false,
    warning = false,
    danger = false,
    className = '',
    showProgress = false,
    readOnly = false,
    placeholder = '',

    step = 1,
    loop = false,
    inputMin = 0,
    inputMax,
    keydownMin,
    keydownMax,
    decimalScale = 0,

    progressValue,
    progressColor,
    progressMin,
    progressMax,

    onChange = () => {},
    onFocus = () => {},
    onBlur = () => {},
    onKeyDown = () => {},
  } = props

  const isEnabled = !off && !disabled && !readOnly

  const containerRef = useRef(null)
  // 點擊 Container 任何都會讓 Input focus
  const localInputRef = useRef(null)
  const mergeRef = useMemo(
    () => mergeRefs([localInputRef, ref]),
    [localInputRef, ref]
  )

  const plusTimeoutID = useRef(null)
  const minusTimeoutID = useRef(null)
  const keyDownTimeoutTimer = 500

  const isKeydownMax = !loop && +value >= +keydownMax
  const isKeydownMin = !loop && +value <= +keydownMin

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       State        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //判斷 input 是否 focus 用來控制 border
  const [isFocus, setIsFocus] = useState(false)
  const [isHover, setIsHover] = useState(false)
  const [isPlusPointerDown, setIsPlusPointerDown] = useState(false)
  const [isMinusPointerDown, setIsMinusPointerDown] = useState(false)

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ ----------------
  //* ----------------- ------------------ -----------------

  const handleClickForContainer = event => isEnabled && setIsFocus(true)

  const handleInputContainerClick = () => localInputRef?.current?.focus()

  const handleInputFocus = () => {
    setIsFocus(true)
    onFocus()
  }
  const handleInputBlur = () => {
    setIsFocus(false)
    onBlur()
  }

  const handlePlus = useCallback(
    event => {
      const hadMax = keydownMax || +keydownMax === 0
      const result = String(
        floorProcess(new Decimal(+value).add(step).toNumber(), step)
      )

      if (!hadMax || result <= keydownMax) onChange({ formattedValue: result })
      if (loop && result > keydownMax) onChange({ formattedValue: keydownMin })
    },
    [keydownMax, keydownMin, loop, onChange, step, value]
  )

  const handleMinus = useCallback(
    event => {
      const hadMin = keydownMin || +keydownMin === 0
      const result = String(
        ceilProcess(new Decimal(+value).sub(step).toNumber(), step)
      )

      if (!hadMin || result >= keydownMin) onChange({ formattedValue: result })
      if (loop && result < keydownMin) onChange({ formattedValue: keydownMax })
    },
    [keydownMax, keydownMin, loop, onChange, step, value]
  )

  const handlePointerUpForPlus = () => {
    clearTimeout(plusTimeoutID.current)
    setIsPlusPointerDown(false)
    plusTimeoutID.current = null
  }
  const handlePointerDownForPlus = () =>
    (plusTimeoutID.current = setTimeout(
      () => setIsPlusPointerDown(true),
      keyDownTimeoutTimer
    ))

  const handlePointerUpForMinus = () => {
    clearTimeout(minusTimeoutID.current)
    setIsMinusPointerDown(false)
    minusTimeoutID.current = null
  }
  const handlePointerDownForMinus = event =>
    (minusTimeoutID.current = setTimeout(
      () => setIsMinusPointerDown(true),
      keyDownTimeoutTimer
    ))

  // 點選 container 外時取消 isFocus
  const handleWindowPointerDown = event => {
    const insideOfContainerClick = containerRef.current.contains(event.target)

    if (!insideOfContainerClick) {
      setIsFocus(false)
    }
  }

  // 判斷 button 長壓開始
  useLayoutEffect(() => {
    window.addEventListener('pointerdown', handleWindowPointerDown)
    return () => {
      window.removeEventListener('pointerdown', handleWindowPointerDown)
    }
  }, [])

  // 判斷 button 長壓結束
  const cancelPointerDown = () => {
    setIsPlusPointerDown(false)
    setIsMinusPointerDown(false)

    clearTimeout(plusTimeoutID.current)
    plusTimeoutID.current = null

    clearTimeout(minusTimeoutID.current)
    minusTimeoutID.current = null
  }
  useLayoutEffect(() => {
    window.addEventListener('pointerup', cancelPointerDown)
    return () => {
      window.removeEventListener('pointerup', cancelPointerDown)
    }
  }, [])

  // button 長壓開始或結束要進行的動作
  useLayoutEffect(() => {
    let topIntervalID
    let bottomIntervalID

    if (isPlusPointerDown)
      topIntervalID = setInterval(() => {
        handlePlus()
      }, 100)

    if (isMinusPointerDown)
      bottomIntervalID = setInterval(() => {
        handleMinus()
      }, 100)

    if (!isPlusPointerDown) {
      clearInterval(topIntervalID)
    }
    if (!isMinusPointerDown) {
      clearInterval(bottomIntervalID)
    }

    return () => {
      clearInterval(topIntervalID)
      clearInterval(bottomIntervalID)
    }
  }, [isPlusPointerDown, isMinusPointerDown, handlePlus, handleMinus])

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        CSS        ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const containerCursor = decideCursor(isEnabled, 'cursor-default')

  const containerClass = `
                    ${className} 
                    ${containerSize[size]}
                    ${containerBg(isEnabled)} 
                    ${containerBorder(danger, isEnabled, isFocus)} 
                    ${containerCursor} 
                    group flex items-center duration-500 select-none 
                    overflow-hidden
                    `

  const inputContainerClass = `flex flex-col justify-center w-full h-full min-w-[10px]`
  const inputClass = `
                      ${inputSize[size]} 
                      ${inputText(warning)} 
                      w-full 
                      text-center font-normal 
                      outline-none 
                      `

  const offClass = `${offSize[size]} w-full text-white/40 text-center tracking-wider`

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Props       ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const leftProps = {
    off,
    disabled,
    icon,
    size,
    isFocus,
    isHover,
    isKeydownMin,
    handleMinus,
    handlePointerDownForMinus,
    handlePointerUpForMinus,
  }

  const rightProps = {
    off,
    disabled,
    unit,
    size,
    isFocus,
    isHover,
    isKeydownMax,
    handlePlus,
    handlePointerDownForPlus,
    handlePointerUpForPlus,
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div
      ref={containerRef}
      className={containerClass}
      onClick={handleClickForContainer}
      onPointerEnter={() => !readOnly && setIsHover(true)}
      onPointerLeave={() => !readOnly && setIsHover(false)}>
      <Left {...leftProps} />

      <div className={inputContainerClass} onClick={handleInputContainerClick}>
        {off ? (
          <div className={offClass}> - - - </div>
        ) : (
          <InputCore
            ref={mergeRef}
            className={inputClass}
            inputType={inputType}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            {...{
              value,
              emptyValue,
              step,
              loop,
              inputMin,
              inputMax,
              keydownMin,
              keydownMax,
              decimalScale,
              placeholder,
              disabled,
              onChange,
              onKeyDown,
            }}
          />
        )}

        {!off && showProgress && (
          <ProgressBar
            className={`${progressSize[size]}`}
            value={progressValue || [value]}
            min={progressMin || [0]}
            max={progressMax || [360]}
            color={progressColor || ['white', '#FFCC66']}
          />
        )}
      </div>

      <Right {...rightProps} />
    </div>
  )
})
export default InputGroup

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Static CSS      -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const containerSize = {
  sm: 'h-8 rounded-[2px]',
  md: 'h-8 rounded-[2px]',
  lg: 'h-14 rounded-md',
}
const containerBg = isEnabled => (isEnabled ? 'bg-dark-1 ' : 'bg-dark-4 ')
const containerBorder = (danger, isEnabled, isFocus) => {
  const common = `border-solid border-1 `

  // disabled || off
  if (!isEnabled) return `${common} border-transparent`

  // danger
  if (danger) return `${common} border-red`

  // focus
  if (isFocus) return `${common} border-teal`

  // normal
  if (!isFocus) return `${common} border-dark-3 hover:border-dark-5 `
}

const offSize = {
  sm: 'text-lg leading-4',
  md: 'text-lg leading-4',
  lg: 'text-xl leading-6',
}

const inputSize = {
  sm: 'text-lg leading-4',
  md: 'text-lg leading-4',
  lg: 'text-xl leading-6',
}
const inputText = warning => (warning ? 'text-yellow' : 'text-white')

const progressSize = { sm: 'h-[1px]', md: 'h-[1px]', lg: 'h-[2px]' }

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Static Func     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
const decideCursor = (isEnabled, enabledCursor) =>
  isEnabled ? enabledCursor : `cursor-not-allowed`

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type validate    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
InputGroup.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.node,
  unit: PropTypes.node,
  off: PropTypes.bool,
  disabled: PropTypes.bool,
  warning: PropTypes.bool,
  danger: PropTypes.bool,
  className: PropTypes.string,
  showProgress: PropTypes.bool,

  step: PropTypes.number,
  inputMin: PropTypes.number,
  inputMax: PropTypes.number,
  keydownMin: PropTypes.number,
  keydownMax: PropTypes.number,
  decimalScale: PropTypes.number,

  progressValue: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
  progressColor: PropTypes.arrayOf(PropTypes.string),
  progressMin: PropTypes.arrayOf(PropTypes.number),
  progressMax: PropTypes.arrayOf(PropTypes.number),

  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
}
