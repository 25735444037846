import React from 'react'

import Common from 'src/containers/operating/device/beamformers/ris/v1/Common'
import BeamSteering from 'src/containers/operating/device/beamformers/ris/v1/BeamSteering'
import Channel from 'src/containers/operating/device/beamformers/ris/v1/Channel'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import { h4 } from 'src/containers/operating/device/beamformers/style'

const RisV1 = props => {
  const { current } = useGetCurrentDeviceData()

  const data_28 = current?.data?.deviceControl?.['28G']
  const data_47 = current?.data?.deviceControl?.['4G7']

  const isShow28 = data_28 && Object.keys(data_28).length > 0
  const isShow47 = data_47 && Object.keys(data_47).length > 0

  return (
    <div className='flex xl:flex-row flex-col gap-4'>
      {isShow47 && (
        <div className={freqContainer}>
          <h4 className={h4 + ' text-center'}>4.7GHz</h4>

          <div className={divider} />

          <div className={itemContainer}>
            {Object.entries(data_47).map(([module, data]) => (
              <ControlItem
                key={`control item 4G7 ${module}`}
                freq='4G7'
                module={module}
              />
            ))}
          </div>
        </div>
      )}

      {isShow28 && (
        <div className={freqContainer}>
          <h4 className={h4 + ' text-center'}>28GHz</h4>

          <div className={divider} />

          <div className={itemContainer}>
            {Object.entries(data_28).map(([module, data]) => (
              <ControlItem
                key={`control item 28G ${module}`}
                freq='28G'
                module={module}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default RisV1

const ControlItem = ({ freq, module }) => {
  return (
    <div className='bg-light-4/10 rounded-lg w-[238px] p-4'>
      <Common freq={freq} module={module} />
      <div className={divider} />
      <BeamSteering freq={freq} module={module} />
      <div className={divider} />
      <Channel freq={freq} module={module} />
    </div>
  )
}

const freqContainer = `bg-light-4/10 w-full p-4 rounded-lg`

export const divider = `w-full h-[1px] bg-dark-5 my-4`

const itemContainer = `
                      flex justify-center 
                      xl:flex-nowrap
                      flex-wrap 
                      gap-4
                      `
