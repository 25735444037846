import React from 'react'
import * as R from 'ramda'
import { useDispatch } from 'react-redux'

import Checkbox from 'src/components/Button/Checkbox'

import StartEndFrequency from './StartEndFrequency'
import FrequencyStepSize from './FrequencyStepSize'
import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

const DynamicFrequency = props => {
  const dispatch = useDispatch()
  const { current } = useGetCurrentDeviceData()
  const sn = current?.sn

  const isOutputting = current.data.settings.output.isOutputting
  const isUseDynamicFreq =
    current.data?.settings?.output.frequency.type === 'dynamic'

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const handleUseDynamicClick = () => {
    let newData = R.clone(current.data)
    newData.settings.output.frequency.type = isUseDynamicFreq
      ? 'fixed'
      : 'dynamic'

    dispatch(setSingleDeviceData({ sn, data: newData }))
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className='funcBG rounded-lg p-4 flex flex-col mb-4'>
      <div
        className='flex items-center gap-x-2 mb-6 cursor-pointer'
        onClick={handleUseDynamicClick}>
        <Checkbox
          disabled={isOutputting}
          status={isUseDynamicFreq ? 'checked' : 'uncheck'} // checked', 'uncheck', 'indeterminate'
        />
        <h3 className='text-base text-light-1'>Set Frequency Range</h3>
      </div>

      <StartEndFrequency />
      <FrequencyStepSize />
    </div>
  )
}

export default DynamicFrequency
