import React, { useState } from 'react'
import InputGroupAppendDialog from 'src/components/composite/InputGroupAppendDialog'

import {
  distanceIcon,
  distanceStep,
  distanceUnit,
  distanceDecimalScale,
  getDistanceWarningText,
} from 'src/constants/beamformers'
import { rangeText } from 'src/containers/operating/device/beamformers/style'
import InputGroup from 'src/components/Input/InputGroup'

const Distance = props => {
  const {
    // Required
    value = '0',
    distanceMin = 0,
    distanceMax = 60,
    onChange = () => {},

    // input props
    className = 'w-full',
    icon = distanceIcon,
    unit = distanceUnit,
    step = distanceStep,
    loop = false,
    validMin = distanceMin,
    validMax = distanceMax,
    decimalScale = distanceDecimalScale,
    off = false,
    size = 'md',

    // dialog props
    placement = 'top',
    overwriteOffset = { top: 0, left: 0 },
    onFocus = () => {},
  } = props

  const [warning, setWarning] = useState('')

  const distanceInputProps = {
    size,
    icon,
    unit,
    step,
    loop,
    validMin,
    validMax,
    keydownMin: validMin,
    keydownMax: validMax,
    decimalScale,
    value,
    off,
  }

  // dialog 要蓋上去的元素
  const overwriteElements = (
    <div>
      <InputGroup warning={true} disabled={true} {...distanceInputProps} />
      <span className={`${rangeText} text-yellow mt-1`}>
        Range {distanceMin} ~ {distanceMax}m
      </span>
    </div>
  )
  const warningText = getDistanceWarningText({
    min: distanceMin,
    max: distanceMax,
    step: distanceStep,
  })

  const distanceDialogProps = {
    placement,
    overwriteOffset,
    overwriteElements: overwriteElements,
    dialogText: warningText[warning],
    warning: warning,
    setWarning: result => setWarning(result),
    setFocus: onFocus,
    changeCallback: onChange,
  }

  return (
    <div className={className}>
      <InputGroupAppendDialog
        {...distanceInputProps}
        {...distanceDialogProps}
      />
      <span className={`${rangeText} text-white/50`}>
        Range {distanceMin} ~ {distanceMax}m
      </span>
    </div>
  )
}

export default Distance
