export const h4 = 'text-sm leading-4 font-medium text-light-1'

export const h6 = 'text-sm leading-4 font-normal text-light-4'

export const small = `text-xs font-normal leading-[14px]`

export const divider = `w-full h-[1px] bg-dark-5`

export const container = `
                        funcBG 
                        w-full
                        p-4
                        rounded-md
                        `

export const contentBox = `py-2 px-1`

export const rangeText = `block text-xs text-white/50 font-normal mt-1`
