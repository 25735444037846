import React from 'react'

import { hDivider } from 'src/containers/operating/device/freqConverter/style'

import RfPortControl from 'src/containers/operating/device/freqConverter/sg/OutputSettings/RfPortControl'
import FixedFrequency from 'src/containers/operating/device/freqConverter/sg/OutputSettings/FixedFrequency'
import OutputPowerStep from 'src/containers/operating/device/freqConverter/sg/OutputSettings/OutputPowerStep'
import OutputMethod from 'src/containers/operating/device/freqConverter/sg/OutputSettings/OutputControls/OutputMethod'
import DynamicFrequency from 'src/containers/operating/device/freqConverter/sg/OutputSettings/DynamicFrequency'
import SweepTimeStep from 'src/containers/operating/device/freqConverter/sg/OutputSettings/OutputControls/SweepTimeStep'
import RepeatRounds from 'src/containers/operating/device/freqConverter/sg/OutputSettings/OutputControls/RepeatRounds'
import ApplyButton from 'src/containers/operating/device/freqConverter/sg/OutputSettings/ApplyButton'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

const OutputSettings = () => {
  const { current } = useGetCurrentDeviceData()

  const isSweep =
    current.data?.settings?.output?.outputControls.method === 'sweep'

  return (
    <div className='funcBG p-4 pb-8 rounded-md'>
      <RfPortControl />

      <div className={hDivider + ' my-4'} />

      <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-2'>
        <FixedFrequency />
        <OutputPowerStep />
      </div>

      <div className={hDivider + ' my-4'} />

      <OutputMethod />

      {isSweep && (
        <>
          <DynamicFrequency />

          <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-2 mb-4'>
            <SweepTimeStep />
            <RepeatRounds />
          </div>
        </>
      )}

      <ApplyButton />
    </div>
  )
}

export default OutputSettings
