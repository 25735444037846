import React from 'react'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'
import useGetUIControl from 'src/hooks/useGetUIControl'

const Channel = ({ freq, module }) => {
  const { current } = useGetCurrentDeviceData()
  const sn = current.sn
  const { single } = useGetUIControl(sn)
  const importFileName = single.deviceControl.importFileName

  const data = current.data.deviceControl?.[freq]?.[module]?.channel

  const countStandard = 32
  const widthStandard = 5.5
  const heightStandard = 5.5
  const gapStandard = 0.5
  const offset = countStandard / data[0].length

  const width = widthStandard * offset
  const height = heightStandard * offset
  const gap = gapStandard * offset

  const _getX = (i, a) => Math.floor(i) * (width + gap)
  const _getY = row_i => Math.floor(row_i * (height + gap))

  return (
    <div className='flex flex-col items-center justify-center'>
      <h3 className='text-sm text-light-1 self-start mb-2'>
        Radiation Pattern
        {/* {importFileName && `- ${importFileName}`} */}
      </h3>

      {/* 2024/11/19 根據 小E 跟 Ivy 口頭討論後， Import 檔案裡面有theta phi，但 不接，所以 Import mode 不顯示 */}
      {/* {!importFileName && (
          <h3 className='text-base text-center text-light-1 mb-4'>
            INC. θ {thetaI}°, φ {phiI}° | REF. θ {thetaR}°, φ {phiR}°
          </h3>
        )} */}

      <div className='w-[206px] h-[206px] flex justify-center items-center'>
        {/* width & height 5.5 + gap 0.5 渲染時不會破圖，所以用這個呎寸 + scale */}
        <svg className='w-[192px] h-[192px] scale-[1.06]'>
          {data.map((row_e, row_i) =>
            row_e.map((e, i, a) => (
              <rect
                key={`ris v1 channel rect ${i}`}
                x={_getX(i, a)}
                y={_getY(row_i)}
                width={width}
                height={height}
                fill={
                  +e ? 'hsla(45, 100%, 81%, 0.7)' : 'hsla(45, 100%, 81%, 0.1)'
                }
              />
            ))
          )}
        </svg>
      </div>
    </div>
  )
}

export default Channel
