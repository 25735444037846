import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import Decimal from 'decimal.js'
import * as R from 'ramda'

import {
  removeComma,
  negativeSwitch,
  fixLeadingZero,
  regProcess,
  setEmptyValue,
  floorProcess,
  ceilProcess,
} from '../../func'
import { RSafe } from 'src/funcs/ramdaFunc'
import { safeNumber, appendComma } from 'src/funcs/tools'

import useBindErrorBoundary from 'src/hooks/useBindErrorBoundary'

//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//! 支援小數點時， value 不要用多個變數相加
//! 因為當輸入到 . 的時候，這個點會不給輸入

const InputNumber = forwardRef(
  (
    {
      id = '',
      dataTestId = '',
      className = '',
      value,
      emptyValue = '0',
      inputMin,
      inputMax = '999999999999999',
      keydownMin,
      keydownMax = '999999999999999',
      step,
      loop,
      disabled,
      placeholder = 0,
      decimalScale = 0,
      onChange = () => {},
      onFocus = () => {},
      onKeyDown = () => {},
      onBlur = () => {},
    },
    ref
  ) => {
    const {
      safeInputMin,
      safeInputMax,
      safeKeydownMin,
      safeKeydownMax,
      safeStep,
      safeDecimalScale,
    } = {
      safeInputMin: inputMin || +inputMin === 0 ? safeNumber(inputMin) : null,
      safeInputMax: inputMax || +inputMax === 0 ? safeNumber(inputMax) : null,
      safeKeydownMin:
        keydownMin || +keydownMin === 0 ? safeNumber(keydownMin) : null,
      safeKeydownMax:
        keydownMax || +keydownMax === 0 ? safeNumber(keydownMax) : null,
      safeStep: step || step === 0 ? safeNumber(step) : 1,
      safeDecimalScale: decimalScale ? safeNumber(decimalScale) : 0,
    }

    // 傳進來的 value 全附上 comma
    const inputValue = appendComma(value)

    // 傳出去的 value 要經過處理 (包含拔掉 comma 變回純數字)
    const responseValue = outputProcessing(safeDecimalScale, emptyValue)

    //* ----------------- ------------------ -----------------
    //* ----------------- ------------------ -----------------
    //* -----------------       Event        -----------------
    //* ----------------- ------------------ -----------------
    //* ----------------- ------------------ -----------------
    const handleKeyDown = useBindErrorBoundary(e => {
      onKeyDown(e)

      let removeCommaValue = removeComma(value)

      const isUp = e.keyCode === 38
      const isDown = e.keyCode === 40

      if (isUp || isDown) {
        let result
        const hadKeydownMin = safeKeydownMin || safeKeydownMin === 0

        if (isUp) {
          const newValue = new Decimal(+removeCommaValue)
            .add(safeStep)
            .toNumber()
          result = String(floorProcess(newValue, safeStep))
        }

        if (isDown) {
          const newValue = new Decimal(+removeCommaValue)
            .sub(safeStep)
            .toNumber()
          result = String(newValue, safeStep)
        }
        if (loop) {
          result = result > safeKeydownMax ? String(safeKeydownMin) : result
          result = result < safeKeydownMin ? String(safeKeydownMax) : result
        }

        const minPass = !hadKeydownMin || +result >= +safeKeydownMin
        const maxPass = !safeKeydownMax || +result <= +safeKeydownMax

        if (minPass && maxPass)
          onChange({
            formattedValue: responseValue(result),
            value: e.target.value,
          })
      }
    })

    const handleChange = event => {
      onChange({
        formattedValue: responseValue(event.target.value),
        value: event.target.value,
      })
    }

    //* ----------------- ------------------ -----------------
    //* ----------------- ------------------ -----------------
    //* -----------------        JSX         -----------------
    //* ----------------- ------------------ -----------------
    //* ----------------- ------------------ -----------------
    // 這邊固定使用 text, 因為好處理之外手機數字鍵盤不支援負數
    return (
      <input
        id={id}
        data-testid={dataTestId}
        ref={ref}
        inputMode='text' // 要同時支援負號 小數點 只有這個
        disabled={disabled}
        className={className}
        type='text'
        min={safeInputMin}
        max={safeInputMax}
        value={inputValue}
        placeholder={placeholder}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    )
  }
)

export default InputNumber

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------    Static Func     -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
export const outputProcessing = (decimalScale, emptyValue) => value =>
  RSafe(
    R.pipe(
      removeComma,
      negativeSwitch,
      fixLeadingZero,
      regProcess(true, decimalScale), // negative , decimalScale
      setEmptyValue(emptyValue)
    )
  )(value)

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------   Type validate    -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
InputNumber.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  decimalScale: PropTypes.number,
  onChange: PropTypes.func.isRequired,
}
