import * as R from 'ramda'

import { mockChannel_default } from '../../constants/ris.mjs'
import { getDefaultCurrentFreq } from '../spec.mjs'
import Controllers from '../../Controllers/index.mjs'

const getDT = sn => Controllers._getDeviceType(sn)

export const risV1InitAppendData = sn => ({
  deviceControl: {
    '4G7': {
      // ...createPortData('ris_1'),
      ...createPortData('ris_2'),
      ...createPortData('ris_3'),
      ...createPortData('ris_4'),
    },
    '28G': {
      ...createPortData('ris_1'),
      // ...createPortData('ris_2'),
      // ...createPortData('ris_3'),
      // ...createPortData('ris_4'),
    },
  },
})

const createPortData = name => ({
  [name]: R.clone({
    steering: {
      incident: {
        // 因爲 source 有可能不成 beam 所以沒取名叫 beamIndex
        index: 0,
        theta: 0,
        thetaMax: 60, // 沒天線所以要先定義
        phi: 0,
        distance: 0,
        distanceMin: 0,
        distanceMax: 500,
      },
      reflection: {
        index: 0,
        theta: 0,
        thetaMax: 60, // 沒天線所以要先定義
        phi: 0,
      },
    },
    channel: mockChannel_default,
  }),
})
