import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useErrorBoundary } from 'react-error-boundary'

import CommonButton from 'src/components/Button/CommonButton'

import operationalFailureImg from 'src/assets/img/operational_failure.png'
import { modalActions } from 'src/redux/slices/modal'

import useGetCurrentDeviceData from 'src/hooks/useGetCurrentDeviceData'

const DeviceSomethingWereWrong = props => {
  const dispatch = useDispatch()

  const { current } = useGetCurrentDeviceData()
  const sn = current.sn

  const {
    preferredUsername: name,
    userID,
    email,
  } = useSelector(state => state.member)

  const error = useSelector(state => state.error)

  const time = error.device?.[sn]?.time
  const api = error.device?.[sn]?.api
  const code = error.device?.[sn]?.code
  const codeName = error.device?.[sn]?.codeName
  const errorInfo = error.device?.[sn]?.errorInfo
  const message = error.device?.[sn]?.message

  const { handleRetry } = props
  const { resetBoundary } = useErrorBoundary()

  const handleReportIssue = () =>
    dispatch(
      modalActions.showModalOfDeviceErrorSendMail({
        name,
        userID,
        email,
        time,
        api,
        code,
        codeName,
        message,
        sn,
        deviceType: errorInfo?.deviceType,
        hwVersion: errorInfo?.versionInfo?.hardware,
        fwVersion: errorInfo?.versionInfo?.firmware,
      })
    )
  const handleRetryClick = () => (handleRetry ? handleRetry() : resetBoundary())

  return (
    <section className={initSection}>
      <img
        src={operationalFailureImg}
        alt='operational Failure Img'
        className='mb-8'
      />

      <div className='text-lg text-light-4 font-normal leading-5'>
        <b>Something went wrong. </b>
        <br />
        <br />
        Please try the following to fix this
        <br />
        <br />
        <div className='text-left ml-1'>
          1. Verify your network connection status.
          <br />
          2. Reboot your device.
          <br />
          3. Click the “Retry” button below to try again.
        </div>
        <br />
        If the problem persists, please contact TMYTEK.
      </div>

      <div className='mt-16 flex gap-x-5'>
        <CommonButton type='outlined' size='lg' onClick={handleReportIssue}>
          Report Issue
        </CommonButton>

        <CommonButton type='general' size='lg' onClick={handleRetryClick}>
          Retry
        </CommonButton>
      </div>
    </section>
  )
}

export default DeviceSomethingWereWrong

// h-[calc(100vh_-_64px_-_24px_-_84px)]
// 64px = 上方空白處
// 24px = operating 下方 padding
// 84px - footer 的 height
const initSection = `[DeviceSomethingWereWrong-initSection] 
                funcBG 
                relative 
                w-full 
                h-[calc(100vh_-_64px_-_24px_-_84px)] 
                flex flex-col 
                items-center justify-center 
                text-light-4 text-3xl font-bold text-center 
                `
