import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import Beam3DControlCore from 'src/components/composite/beamformers/Beam3DControl/Core'

import {
  degreeToRadian,
  radianToDegree,
  getSphericalByCartesian,
  topViewXyzToCartesianXyz,
  leftViewXyzToCartesianXyz,
} from '../tools'

import DragHVModeReference from './DragHVModeReference'
import HVModeButtons from './HVModeButtons'

import useBindErrorBoundary from 'src/hooks/useBindErrorBoundary'
import { phiMax } from 'src/constants/beamformers'

//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
//* -----------------      Example       -----------------
//* ----------------- ------------------ -----------------
//* ----------------- ------------------ -----------------
// <Beam3DControlForCloverCell
//   {...{
//     sn = '',
//     deviceType = 0,
//     dragHVMode = 'horizon',
//     handleDragHVModeChange = () => {},
//     thetaH = 0,
//     thetaV = 0,
//     phiH = 0,
//     phiV = 0,
//     thetaMaxH = 0,
//     thetaMaxV = 0,
//     handleDrag = () => {},
//     handleDragEnd = () => {},
//     beam3DCameraArgs = {
//       position: { x: 0, y: 0, z: 0 },
//       rotation: { x: 0, y: 0, z: 0 },
//     },
//     handleCameraChange = () => {},
//     cameraReset = false,
//     handleCameraReset = () => {},
//     deviceDirection = 'top', // top, left
//   }}
// />

const Beam3DControlForCloverCell = ({
  sn = '',
  deviceType = 0,
  dragHVMode = 'horizon',
  handleDragHVModeChange = () => {},
  thetaH = 0,
  thetaV = 0,
  phiH = 0,
  phiV = 0,
  thetaMaxH = 0,
  thetaMaxV = 0,
  isOffH,
  isOffV,
  handleDrag = () => {},
  handleDragEnd = () => {},
  beam3DCameraArgs = {
    position: { x: 0, y: 0, z: 0 },
    rotation: { x: 0, y: 0, z: 0 },
  },
  handleCameraChange = () => {},
  isCameraReset = false,
  handleCameraReset = () => {},
  deviceDirection = 'top', // top, left
}) => {
  const [isDragging, setIsDragging] = useState(false)

  const isDraggable = true
  const power = 10.7

  const thetaBallRef = useRef(null)
  const phiBallRef = useRef(null)
  const beamHRef = useRef(null)
  const beamVRef = useRef(null)

  const is3DControlDisabled = isOffH && isOffV

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------     Life Cycle     -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  // beam 位置不管 top or left 都在對角線,
  // + 180 degree 來調到正確位置
  const beamOffset = 180

  const ballThetaMax = dragHVMode === 'horizon' ? thetaMaxH : thetaMaxV
  let ballTheta = dragHVMode === 'horizon' ? thetaH : thetaV
  let ballPhi = dragHVMode === 'horizon' ? phiH : phiV

  // 每次更新 theta & phi 更新 beam
  // (disk & ball 元件內部更新)
  useEffect(() => {
    if (beamHRef.current) {
      const limitedTheta = thetaH >= thetaMaxH ? thetaMaxH : thetaH
      const limitedPhi = phiH >= phiMax ? phiMax : phiH
      beamHRef.current.rotation.set(
        0,
        degreeToRadian(+limitedPhi + beamOffset),
        degreeToRadian(+limitedTheta)
      )
    }

    if (beamVRef.current) {
      const limitedTheta = thetaV >= thetaMaxV ? thetaMaxV : thetaV
      const limitedPhi = phiV >= phiMax ? phiMax : phiV
      beamVRef.current.rotation.set(
        0,
        degreeToRadian(+limitedPhi + beamOffset),
        degreeToRadian(+limitedTheta)
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    phiH,
    phiV,
    thetaH,
    thetaV,
    beamHRef.current,
    beamVRef.current,
    dragHVMode,
  ])

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        Func        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  // 限制theta 不超過最大 theta
  const thetaMaxLimit = theta =>
    Math.max(-ballThetaMax, Math.min(ballThetaMax, theta))

  // phi 校正，因為滑鼠繞一圈會繞到 viewX viewZ 的
  // 正數區塊 與 負數區塊  (phi 的結果會變成 -180 ~ 180)
  // 這邊透過補正把他補成完整的 "正數圓"，作為 phi 使用
  const phiCalibration = degreePhi =>
    deviceDirection === 'top'
      ? (degreePhi + 180 + 90) % 360 // + 180 = 把 max 補滿 360 / + 90 = 讓球的位置跟滑鼠一致 / % 360 = 永遠都不會超過 360
      : (degreePhi - 180) * -1 // - 180 = 把 max 補滿 -360 / left 是反著轉 直接 * -1

  // ==============================
  // [getCartesianXyzByViewXyz]
  // ==============================
  // view XYZ 轉 笛卡爾 XYZ, deviceDirection 轉向不同，相對應 XYZ 也不同
  const getCartesianXyzByViewXyz = (deviceDirection, [vx, vy, vz]) => {
    if (deviceDirection === 'top') return topViewXyzToCartesianXyz(vx, vy, vz)
    if (deviceDirection === 'left') return leftViewXyzToCartesianXyz(vx, vy, vz)
  }

  // ==============================
  // [mousePointProcess]
  // ==============================
  // power 軸若是低於零，抓的點會跑到對向去
  const preventCatchToBackSide = BackSide => (BackSide < 0 ? 0 : BackSide)

  // mouse event 預處理 (限制 power 不會小於 0 , 限制 lite 只能水平移動)
  const mousePointProcess = (deviceDirection, [mouseX, mouseY, mouseZ]) => {
    if (deviceDirection === 'top')
      return [
        mouseX, // horizontal
        preventCatchToBackSide(mouseY), // depth
        mouseZ, // vertical
      ]

    if (deviceDirection === 'left')
      return [
        preventCatchToBackSide(mouseX), // depth
        mouseY, // vertical
        mouseZ, // horizontal
      ]
  }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Event        -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* [handleOnDrag]
  const handleOnDrag = useBindErrorBoundary((target, mouse3D) => {
    // target = theta or phi , 判斷是控制 theta ball or phi ball

    // 抓到滑鼠所在的 viewXyz (radian)
    const viewXyz = mousePointProcess(deviceDirection, mouse3D)

    // 轉成 笛卡爾的 xyz (radian)
    const cXyz = getCartesianXyzByViewXyz(deviceDirection, viewXyz)

    // 轉成 球座標 theta & phi (radian)
    const [, radianTheta, radianPhi] = getSphericalByCartesian(cXyz)

    const oldTheta = ballTheta
    const degreeTheta = radianToDegree(radianTheta)
    const degreePhi = radianToDegree(radianPhi)

    // 限制最大 theta
    const newTheta = thetaMaxLimit(degreeTheta)

    // 操作的是 theta ball 才能同時改變 theta + phi
    // 操作的是 phi ball 只能改變 phi 所以吃舊的 theta
    const resultTheta = target === 'theta' ? newTheta : oldTheta
    const resultPhi = phiCalibration(degreePhi)

    // 更新 theta & phi
    // 由 useEffect 的 theta & phi 更新改變 disk, dotted - line 的 position
    // 去掉多餘的小數點 (degree)
    handleDrag({
      resultTheta: Math.floor(resultTheta),
      resultPhi: Math.floor(resultPhi),
    })

    return null
  })

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------       Props       ------------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  const dragProps = isDraggable
    ? {
        onDragStart: () => setIsDragging(true),
        onDrag: (name, mouse3D) => handleOnDrag(name, mouse3D),
        onDragEnd: event => {
          handleDragEnd(event)
          setIsDragging(false)
        },
      }
    : {
        onDragStart: () => void null,
        onDrag: (name, mouse3D) => void null,
        onDragEnd: () => void null,
      }

  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  //* -----------------        JSX         -----------------
  //* ----------------- ------------------ -----------------
  //* ----------------- ------------------ -----------------
  return (
    <div className='relative w-full h-full select-none '>
      <HVModeButtons
        {...{ isOffH, isOffV, dragHVMode, handleDragHVModeChange }}
      />

      <Beam3DControlCore
        {...{
          sn, // str
          deviceType, // str
          beam3DCameraArgs, // { position: { x: 0, y: 0, z: 0 }, rotation: { x: 0, y: 0, z: 0 }, }
          deviceDirection, // 'top', 'left'
          isCameraReset, // bool
          isDragging, // bool
          handleDrag, // func
          handleDragEnd, // func
          handleCameraChange, // func
          handleCameraReset, // func
        }}>
        {!isOffH && (
          <Beam3DControlCore.Beam
            ref={beamHRef}
            isActive={dragHVMode === 'horizon'}
            onClick={() => handleDragHVModeChange('horizon')}
          />
        )}
        {!isOffV && (
          <Beam3DControlCore.Beam
            ref={beamVRef}
            isActive={dragHVMode === 'vertical'}
            onClick={() => handleDragHVModeChange('vertical')}
          />
        )}

        {!is3DControlDisabled && (
          <Beam3DControlCore.BallAndDisk
            {...{
              power,
              theta: ballTheta,
              thetaMax: ballThetaMax,
              phi: ballPhi,
              thetaBallRef,
              phiBallRef,
              dragProps,
            }}
          />
        )}

        {!is3DControlDisabled && <DragHVModeReference {...{ dragHVMode }} />}
      </Beam3DControlCore>
    </div>
  )
}

export default Beam3DControlForCloverCell

Beam3DControlForCloverCell.propTypes = {
  sn: PropTypes.string,
  deviceType: PropTypes.number,
  dragHVMode: PropTypes.oneOf(['horizon', 'vertical']),
  handleDragHVModeChange: PropTypes.func,
  thetaH: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  thetaV: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  phiH: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  phiV: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  thetaMaxH: PropTypes.number,
  thetaMaxV: PropTypes.number,
  handleDrag: PropTypes.func,
  handleDragEnd: PropTypes.func,
  handleCameraChange: PropTypes.func,
  cameraReset: PropTypes.bool,
  handleCameraReset: PropTypes.func,
  deviceDirection: PropTypes.oneOf(['top', 'left']),
}
