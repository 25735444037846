import React from 'react'

import LEDIndicators from 'src/containers/operating/device/freqConverter/udModule/DeviceSettings/LEDIndicators'
import RefSource from 'src/containers/operating/device/freqConverter/udModule/DeviceSettings/RefSource'

import { hDivider } from 'src/containers/operating/device/freqConverter/style'

const OtherSettings = props => {
  return (
    <div className={container}>
      <LEDIndicators />

      <div className={hDivider} />

      <RefSource />
    </div>
  )
}

export default OtherSettings

const container = `[OtherSettings-container] 
                  funcBG 
                  w-full
                  rounded-md
                  px-4 pt-4 pb-10 
                  flex flex-col 
                  gap-y-4 
                  `
