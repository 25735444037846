import React from 'react'

import LEDIndicators from 'src/containers/operating/device/freqConverter/udbox/UD0630DeviceSettings/LEDIndicators'
import RefSignalSource from 'src/containers/operating/device/freqConverter/udbox/UD0630DeviceSettings/RefSignalSource'
import InputSource from 'src/containers/operating/device/freqConverter/udbox/UD0630DeviceSettings/InputSource'
import OutputSignalSource from 'src/containers/operating/device/freqConverter/udbox/UD0630DeviceSettings/OutputSignalSource'
import OutputLOFrequency from 'src/containers/operating/device/freqConverter/udbox/UD0630DeviceSettings/OutputLOFrequency'

import { hDivider } from 'src/containers/operating/device/freqConverter/style'

const UD0630DeviceSettings = () => {
  return (
    <div className={container}>
      <LEDIndicators />

      <div className={hDivider} />

      <RefSignalSource />
      <InputSource />

      <div className={hDivider} />

      <OutputSignalSource />
      <OutputLOFrequency />
    </div>
  )
}

export default UD0630DeviceSettings

const container = `[UD0630DeviceSettings-container] 
                  funcBG 
                  w-full
                  rounded-md
                  px-4 pt-4 pb-10 
                  flex flex-col 
                  gap-y-4 
                  `
