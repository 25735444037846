import { call, put } from 'redux-saga/effects'

import { getCurrentData } from 'src/redux/sagas/selector/deviceData'
import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import { devWarLog } from 'src/funcs/tools'

export function* steeringThetaPhi(response) {
  try {
    const [sn, { freq, module, channel }] = Object.entries(response?.data)[0]

    const newCurrentData = yield call(getCurrentData, sn)
    newCurrentData.deviceControl[freq][module].channel = channel

    yield put(setSingleDeviceData({ sn, data: newCurrentData }))
  } catch (error) {
    devWarLog(error)
  }
}
