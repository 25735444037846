import React from 'react'

import operationalFailureImg from 'src/assets/img/operational_failure.png'

const NotSupportScreenSize = props => {
  const { supportWidthSize } = props

  return (
    <section className={initSection}>
      <img
        src={operationalFailureImg}
        alt='operational Failure Img'
        className='mb-8'
      />

      <div className='w-[300px] text-base text-light-4 font-normal leading-5'>
        Not supported below {supportWidthSize} resolution. Please use the
        desktop computer to continue.
      </div>
    </section>
  )
}

export default NotSupportScreenSize

// h-[calc(100vh_-_64px_-_24px_-_84px)]
// 64px = 上方空白處
// 24px = operating 下方 padding
// 84px - footer 的 height
const initSection = `[NotSupportScreenSize-initSection] 
                funcBG 
                relative 
                w-full 
                h-[calc(100vh_-_64px_-_24px_-_84px)] 
                flex flex-col 
                items-center justify-center 
                text-light-4 text-3xl font-bold text-center 
                `
