import React from 'react'

import noAntennaImg from 'src/assets/img/no_antenna.png'

const NoAntenna = props => {
  return (
    <div className={container}>
      {/* 這一層是因為設計師要這一頁架構跟 3d instruction mask 架構一樣 */}
      {/* 所以用 div 改裝 */}
      <div className='h-[280px] flex items-end'>
        <img
          src={noAntennaImg}
          alt='TMXLab-kit bbox - no antenna'
          className={img}
        />
      </div>

      <h4 className={h4}>No Antenna</h4>
      <p className={p}>
        Enabled beam steering simulation when the Antenna has an item selected.
      </p>
    </div>
  )
}

export default NoAntenna

const container = `funcBG 
                  w-full h-[551px] 
                  flex flex-col 
                  items-center 
                  text-light-4 
                  text-base leading-5 text-center
                  px-8 pt-[60px] 
                  rounded-lg
                  `
const img = `w-[240px] h-[160px] mb-6`
const h4 = `font-bold`
const p = `w-[336px] font-normal`
