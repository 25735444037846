import React from 'react'

import RfMode from 'src/containers/operating/device/beamformers/bboard/Common/RfMode'
import Frequency from 'src/containers/operating/device/beamformers/bboard/Common/Frequency'

const Common = props => {
  return (
    <div className={container}>
      {/* phase 1 沒有會員所以沒有 config save */}
      {/* <div className={devText + ' hidden md:flex h-[24px] '}>
        Save
      </div>

      <div className={divider + ' hidden md:block'} /> */}

      <div className={bodyContainer}>
        <div className={bodyBox}>
          <h6 className={subtitle}>Current Mode</h6>
          <RfMode />
        </div>

        <div className={bodyBox}>
          <h6 className={subtitle}>Central Frequency</h6>
          <Frequency />
        </div>
      </div>
    </div>
  )
}

Common.propTypes = {}

export default Common
const container = `[Common-container] 
                    funcBG 
                    px-3 xl:px-2 py-2 
                    flex flex-col 
                    bg-light-4/10 
                    backdrop-blur-sm 
                    rounded-md
                    `

const bodyContainer = `
                    [Common-bodyContainer] 
                    w-full h-[75px] lg:h-[60px] 
                    flex items-center
                    `
const bodyBox = `[Common-bodyBox] w-1/2 px-2 flex flex-col gap-y-1`

const subtitle = `[Common-subtitle] text-white text-sm font-normal leading-5`
