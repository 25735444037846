import * as R from 'ramda'
import { call, put } from 'redux-saga/effects'

import { getCommonArgs } from 'src/redux/sagas/selector/deviceData'

import { setSingleDeviceData } from 'src/redux/slices/deviceData'

import __socket_API_sender from 'src/redux/sagas/services/socketTools/__socket_API_sender'
import beamformersApi from 'src/redux/sagas/services/socketAPI/beamformers'

import {
  setCameraArgs,
  setImportFileName,
  setShowImportModeMask,
} from 'src/redux/slices/uiControl/beamformers/ris'

import { devWarLog } from 'src/funcs/tools'

function* _updateSteeringThetaPhiChange({ payloads }) {
  const { sn, freq, module, targetBeam, theta, phi } = payloads

  let { currentData } = yield call(getCommonArgs, sn)

  if (targetBeam !== null) {
    currentData.deviceControl[freq][module].steering[targetBeam].theta = theta
    currentData.deviceControl[freq][module].steering[targetBeam].phi = phi

    yield put(setSingleDeviceData({ sn, data: currentData }))
  }

  return {
    incident: currentData.deviceControl[freq][module].steering.incident,
    reflection: currentData.deviceControl[freq][module].steering.reflection,
  }
}

function _changeValueTypeToNumber({ incident, reflection }) {
  const number_incident = {
    ...incident,
    theta: +incident.theta,
    phi: +incident.phi,
    distance: +incident.distance,
  }

  const number_reflection = {
    ...reflection,
    theta: +reflection.theta,
    phi: +reflection.phi,
  }

  return { number_incident, number_reflection }
}

export function* steeringThetaPhiChange(payloads) {
  try {
    // payloads format: { sn, freq, module, targetBeam, theta, phi, isValid }
    const { sn, freq, module, isValid } = payloads

    const { incident, reflection } = yield call(_updateSteeringThetaPhiChange, {
      payloads,
    })

    if (isValid) {
      let { lookupID } = yield call(getCommonArgs, sn)

      const { number_incident, number_reflection } = _changeValueTypeToNumber({
        incident,
        reflection,
      })

      yield call(__socket_API_sender, beamformersApi.RIS_STEERING_CHANGE_V1, {
        sn,
        lookupID,
        freq,
        module,
        incident: number_incident,
        reflection: number_reflection,
      })
    }
  } catch (error) {
    devWarLog('[handler] steeringThetaPhiChange error:', error)
  }
}

export function* steeringDistanceChange(payloads) {
  try {
    const { sn, freq, module, value, isValid } = payloads

    let { currentData } = yield call(getCommonArgs, sn)

    // 如果是 import mode 點擊 ok 的話 value 會 === null
    // 這時就不用改值
    if (value !== null) {
      currentData.deviceControl[freq][module].steering.incident.distance = value
      yield put(setSingleDeviceData({ sn, data: currentData }))
    }

    if (isValid) {
      let { currentData, lookupID } = yield call(getCommonArgs, sn)
      const incident = currentData.deviceControl[freq][module].steering.incident
      const reflection =
        currentData.deviceControl[freq][module].steering.reflection

      const { number_incident, number_reflection } = _changeValueTypeToNumber({
        incident,
        reflection,
      })

      yield call(__socket_API_sender, beamformersApi.RIS_STEERING_CHANGE_V1, {
        sn,
        lookupID,
        freq,
        module,
        incident: number_incident,
        reflection: number_reflection,
      })
    }
  } catch (error) {
    devWarLog('[handler] steeringDistanceChange error:', error)
  }
}

export function* steeringImportModeMaskOkClick(payloads) {
  try {
    const { sn } = payloads

    yield put(setImportFileName({ sn, value: null }))
    yield put(setShowImportModeMask({ sn, value: false }))

    // call distance change 就會 call steering input change api
    // 然後 更新 channel 了
    yield call(steeringDistanceChange, {
      sn,
      value: null,
      index: null,
      isValid: true,
    })
  } catch (error) {
    devWarLog('[handler] steeringImportModeMaskOkClick error:', error)
  }
}

export function* steering3DCameraChange(action) {
  yield put(setCameraArgs(action.payload))
}
